 <template>
    <div class="text-center">
         <v-dialog  v-on:keyup.esc="close" v-model="dialog" persistent :max-width="options.width" :style="{ zIndex: options.zIndex }">
            <v-overlay :absolute="true" :value="loadingModal">
                <v-progress-circular :size="40" color="amber" indeterminate></v-progress-circular>
            </v-overlay>

              <div :class="`card zn-card card-custom wave wave-animate-slow wave-${color} mb-8 mb-lg-0`">
                    <div class="card-body">
                         <v-btn @click="dialog = false" icon medium :color="color" style="position: absolute;right: 6px;">
                            <v-icon style="font-size: 25px !important;">mdi-close-circle-outline</v-icon>
                        </v-btn>
                        <div class="d-flex align-items-center p-5">
                           <div class="mr-6">
                               <i :class="`la ${icon} zn-notif-icon text-`+color" style="top: 20px;position: absolute;left: 15px;"></i>
                            </div>
                            <div class="d-flex flex-column ml-5">
                                <div :class="'font-size-h4 mb-0 text-'+color" style="font-weight: 500;">
                                    {{title}}
                                </div>
                                <div :class="'text-'+color" style="font-weight: 100;">
                                  {{message}}
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
         </v-dialog>
     </div>
</template>

<script>
export default {
    data() {
      return {
        loadingModal:false,
        dialog: false,
        message: null,
        color:null,
        title: null,
        icon: null,
         options: {
          color: "primary",
          width: 350,
          zIndex: 200,
        },
      };
    },

    methods: {
      
      success(message,title){
        this.dialog = true
        this.title = title || 'Berhasil'
        this.message = message
        this.color = 'success'
        this.icon = 'la-check-circle'
      },
      danger(message,title){
        this.dialog = true
        this.title = title || 'Gagal'  
        this.message = message
        this.color = 'danger'
        this.icon = 'la-frown-o'
      },
       warning(message,title){
        this.dialog = true
        this.title = title || 'Peringatan'  
        this.message = message
        this.color = 'warning'
        this.icon = 'la-comment-o'
      },
       info(message,title){
        this.dialog = true
        this.title = title || 'Informasi'  
        this.message = message
        this.color = 'info'
        this.icon = 'la-info-circle'
      },
      close(){
        this.dialog = false;
      }
     
    },
  };

// export default {
//     props: ['show', 'judul'],
//     name: 'dialogConfirm',
// }
</script>

